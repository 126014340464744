import React,{useEffect, useState,useMemo} from "react";
import "../../../app/base-style.css";
import { appService } from "../../../../services/app.service";
import {
  Typography,
  Button,
  OutlinedInput,
  MenuItem,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  ListSubheader
} from "@material-ui/core";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./_new-service-agreement.css";
import { OutlinedTextFiled } from "../../../auth/auth-style";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const useStylesCustom = makeStyles((theme) => ({
    lightGreyColor:{
      color: theme.palette.quaternary.main
    }
  }));

function PropertyDetails(props) {
    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.PROPERTY_DETAILS);
    const labelsApplicantLabels = useSelector((state)=>state?.labels?.data?.getLabels?.APPLICANT_DETAILS);
    let coolingCapacity_length = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COOLING_CAPACITY_MAXLENGTH);
    coolingCapacity_length = Number(coolingCapacity_length);
    let placeholder_max_length = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PLACEHOLDER_MAX_LENGTH);
    placeholder_max_length = Number(placeholder_max_length);
    let mailing_address_max_length = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MAILING_ADDRESS_MAX_LENGTH);
    mailing_address_max_length = Number(mailing_address_max_length);

    const classesLocal = useStylesCustom();
    const [loading, setLoading] = useState(false);
    const [uniqueRadioValue, setUniqueRadioValue] = useState("individual");
    const [premises, setPremises] = useState([]);
    const [districts, setDistricts] = useState([]);
    const containsText = (text, searchText) =>
      text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    useEffect(() => {
        setLoading(true);
        let typeProperty = props.propertyType;
        if (typeProperty) {
            setUniqueRadioValue(typeProperty);
        }
        appService.getPremises().then((res) => {
          if (res.data.getPremiseFinder != undefined) {
            setPremises(res.data.getPremiseFinder);
            setLoading(false);
            let states = [
              ...new Set(res.data.getPremiseFinder.map((data) => data.stateDescr)),
            ];
            setDistricts(states);
          }
        });
    }, [])

    const [description, setDescription] = useState("");
    const describe = (e) => {
        props.setadlnotes(e.target.value)
        setDescription(e.target.value);
      };

    const [dialogOpen, setDialogOpen] = useState(false)
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleCloseForced = () => {
        setDialogOpen(false);
    };

    const [districtLocal, setDistrict] = useState("");
    const [addressLocal, setAddress] = useState("");
    const [buildingNew, setBuildingNew] = useState("");
    const [building, setBuilding] = useState("");
    const [unitLocal, setUnit] = useState("");
    const [selectedPremise, setSelectedPremise] = useState("")

    const handleClose = () => {
        setDialogOpen(false);
        let selectedPrem = premises.filter(
          (premise) =>
            premise.stateDescr === districtLocal &&
            premise.address3 === buildingNew &&
            premise.address4 === addressLocal &&
            premise.address2 === building &&
            premise.address1 === unitLocal
        );
        let lead = selectedPrem[0]["leadId"];
        let connectionId = selectedPrem[0]["connectionId"];
        localStorage.setItem("connectionId",connectionId);
        localStorage.setItem("lead",lead);
        props.setLeadid(lead);
        let depositAmount = selectedPrem[0]["depositAmount"];
        props.setDepositeAmount(selectedPrem[0]["depositAmount"]);
        let selectedPremise = unitLocal;
        props.setSelectedPremise(selectedPremise);
        if(selectedPrem){
          if(selectedPrem[0].stateDescr === "Villas"){
            props.setPropertyType("villa")
            setUniqueRadioValue("villa");
        }else{
          props.setPropertyType("individual")
          props.setCooling();
        setUniqueRadioValue("individual");
        }
      }
      };

    const [addressFieldShow, setAddressFieldShow] = useState(true);
    const [units, setUnits] = useState([]);
    const [buildingsNew, setBuildingsNew] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [addresses, setAddresses] = useState([])
    const [selectedValue, setSelectedValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
      var buildingsNew = [
          ...new Set(
            premises
              .filter((addr) => addr.stateDescr === districtLocal)
              .map((premise) => premise.address3)
          ),
        ];
        if(districtLocal == "Villas"){
          setAddressFieldShow(false)
        }
        setBuildingsNew(buildingsNew)
        setBuildingNew("");
        setAddress("");
        setBuilding("");
        setUnit("");
        setBuildings([]);
        setUnits([]);
  }, [districtLocal])

    useEffect(() => {
        var addresses = [
            ...new Set(
              premises
                .filter((addr) => addr.address3 === buildingNew)
                .map((premise) => premise.address4)
            ),
          ];
          setAddresses(addresses)
          setAddress("");
          setBuilding("");
          setUnit("");
          setBuildings([]);
          setUnits([]);
    }, [buildingNew])

    useEffect(() => {
        if (
            addresses.length === 1 &&
            addresses[0] === null
          ) {
                setAddress(null);
                setAddressFieldShow(false);
                var buildings = [
                  ...new Set(
                    premises
                      .filter(
                        (building) =>
                          building.stateDescr === districtLocal &&
                          building.address3 == buildingNew &&
                          building.address4 === addressLocal
                      )
                      .map((premise) => premise.address2)
                  ),
                ];
                setBuildings(buildings);
                setBuilding("");
                setUnit("");
                setUnits([]);
          } else if (
            addresses.length !== 1 &&
            addresses.includes(null)
          ) {
            setAddress(null);
            setAddressFieldShow(true);
                var buildings = [
                  ...new Set(
                    premises
                      .filter(
                        (building) =>
                          building.stateDescr === districtLocal &&
                          building.address3 == buildingNew &&
                          building.address4 === null
                      )
                      .map((premise) => premise.address2)
                  ),
                ];
                setBuildings(buildings);
                setBuilding("");
                setUnit("");
                setUnits([]);
              }
    }, [addresses])
    
    
    const selectHandleChange = (event) => {
        setDistrict(event.target.value);
        setAddressFieldShow(true);
      };

    useEffect(() => {
        var buildings = [
            ...new Set(
              premises
                .filter(
                  (building) =>
                    building.stateDescr === districtLocal &&
                    building.address3 == buildingNew &&
                    building.address4 === addressLocal
                )
                .map((premise) => premise.address2)
            ),
          ];
          let sortBuildings = buildings.sort((a, b) => 
            isNaN(a) && isNaN(b) ? 
              (a.match(/[A-Za-z\s]+/)[0] === b.match(/[A-Za-z\s]+/)[0] ? 
                parseInt(a.match(/\d+/)[0], 10) - parseInt(b.match(/\d+/)[0], 10) : a.localeCompare(b)) : 
              parseInt(a, 10) - parseInt(b, 10)
          );
          setBuildings(sortBuildings?sortBuildings:buildings);
          setBuilding("");
          setUnit("");
          setUnits([]);
          
    }, [addressLocal])
    
    const selectAddressHandleChange = (event) => {
        setAddress(event.target.value);
      };
    
    useEffect(() => {
        var unitsObj = [
            ...premises.filter(
              (unit) =>
                unit.stateDescr === districtLocal &&
                unit.address3 == buildingNew &&
                unit.address4 === addressLocal &&
                unit.address2 === building
            ),
          ];
          var units = [...new Set(unitsObj.map((premise) => premise.address1))];
          var lead = [...new Set(unitsObj.map((premise) => premise.leadId))];
          setUnit("");
          setUnits(units);
    }, [building])
    useEffect(() => {
      if(units.length>0)
          setUnit(units[0])
    }, [units])
    
    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(
      () => buildings.filter((option) => containsText(option, searchText)),
      [searchText,buildings]
    );

    
    const selectBuildingHandleChange = (event) => {
        setBuilding(event.target.value);
        setSearchText("")
      };

    const selectBuildingNewHandleChange = (event) => {
        setBuildingNew(event.target.value);
      };

    const selectUnitHandleChange = (event) => {
        setUnit(event.target.value);
      }
    
    const [buildingNumberRequirelocal, setBuildingNumberRequire] = useState(false);
    const [buildNumberErrorlocal, setBuildNumberError] = useState(false);
    const coolingBlur = (e) => {
        const name = e.target.value;
        if (name) {
        setBuildNumberError(false);
        setBuildingNumberRequire(false);
        } else {
        setBuildNumberError(true);
        setBuildingNumberRequire(true);
        }
      };

      let MOBILE_REGEX = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_REGEX);
      MOBILE_REGEX=MOBILE_REGEX.replace(
        "\\\\",
        "\\"
      );
    const coolingCapacityHandleChange = (e) => {
        const { name, value } = e.target;
        const re=RegExp(MOBILE_REGEX)
        if (e.target.value === "" || re.test(e.target.value)) {
        props.setCooling(value);
        }
      };

      const {
        unit,
        unitHandleChange,
        unitBlur,
        unitError,
        bname,
        buildingNameRequire,
        buildingNameHandleChange,
        buildingNameBlur,
        bnum,
        buildingNumberRequire,
        buildNumberHandleChange,
        buildingNumberBlur,
        address,
        addressRequire,
        district,
        districtRequire,
        addressHandleChange,
        addressBlur,
        districtBlur,
        districtHandleChange,
        pobox,
        poBoxHandleChange,
        premiseRequire,
      } = props;

  return (
    <div>
        {labels ? (
          <div>
              <div className="horizontalLine">
              <Typography
                color="primary"
                gutterBottom
                variant="subtitle1"
                component="h2"
                className="margin-top-10"
              >
                <h5>
                  <b>{labels.PREMISE_DETAILS}</b>
                </h5>
              </Typography>
            </div>{" "}
            <Button
              variant="contained"
              color="secondary"
              className={`margin-top-10 textTransform ${props.customStyle.forthButton}`}
              endIcon={<FindInPageIcon>send</FindInPageIcon>}
              onClick={handleClickOpen}
            >
              {labels.SELECT_PREMISE}
            </Button>
            {premiseRequire && selectedPremise === "" && (
              <Typography color="error" variant="subtitle2">
                {labels.PREMISE_REQUIRED}
              </Typography>
            )}
            <Dialog
              fullWidth={true}
              maxWidth={"xs"}
              onClose={handleCloseForced}
              aria-labelledby="customized-dialog-title"
              open={dialogOpen}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleCloseForced}
              >
                <Typography
                  color="primary"
                  gutterBottom
                  variant="subtitle1"
                  component="h1"
                  style={{ margin: "auto", fontSize: "1.2rem" }}
                >
                  <b>{labels.SELECT_PREMISE}</b>
                </Typography>
              </DialogTitle>

              <DialogContent dividers>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : null}
                {!loading ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" color="primary">
                      {labels.SELECT_PREMISE_DISTRICT}
                    </Typography>
                    <FormControl className={props.customStyle.formControl}>
                      <Select
                        fullWidth
                        displayEmpty
                        variant="outlined"
                        value={districtLocal}
                        onChange={selectHandleChange}
                        input={
                          <OutlinedInput
                            classes={{
                              input: props.customStyle.selectHeight,
                            }}
                          />
                        }
                      >
                        <MenuItem
                          className={classesLocal.lightGreyColor}
                          key={"address"}
                          value=""
                          disabled
                        >
                          <span className={classesLocal.lightGreyColor}>
                            {labels.PLEASE_SELECT_DISTRICT}
                          </span>
                        </MenuItem>
                        {districts.map((dist, index) => (
                          <MenuItem key={index + 1} value={dist}>
                            <ListItemText primary={dist} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                ) : null}
                <br />
                <br />

                {districtLocal ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" color="primary">
                      {labels.SELECT_BULIDING}
                    </Typography>
                    <FormControl className={props.customStyle.formControl}>
                      <Select
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        value={buildingNew}
                        onChange={selectBuildingNewHandleChange}
                        input={
                          <OutlinedInput
                            classes={{
                              input: props.customStyle.selectHeight,
                            }}
                          />
                        }
                      >
                        <MenuItem
                          className={classesLocal.lightGreyColor}
                          key={"buildingNew"}
                          value=""
                          disabled
                        >
                          <span className={classesLocal.lightGreyColor}>
                            {labels.PLEASE_SELECT_BUILDING}
                          </span>
                        </MenuItem>
                        {buildingsNew.map((building, index) => (
                          <MenuItem key={index + 1} value={building}>
                            <ListItemText
                              primary={
                                building ? building : labels.DROPDOWN_OTHERS
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </React.Fragment>
                ) : null}

                {districtLocal && addressFieldShow ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" color="primary">
                      {labels.BUILDING_NAME}
                    </Typography>
                    <FormControl className={props.customStyle.formControl}>
                      <Select
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        value={addressLocal}
                        onChange={selectAddressHandleChange}
                        input={
                          <OutlinedInput
                            classes={{
                              input: props.customStyle.selectHeight,
                            }}
                          />
                        }
                      >
                        <MenuItem
                          className={classesLocal.lightGreyColor}
                          key={"address"}
                          value=""
                          disabled
                        >
                          <span className={classesLocal.lightGreyColor}>
                            {labels.PLEASE_SELECT_BUILDING}{" "+labelsApplicantLabels.APPLICANT_NAME}
                          </span>
                        </MenuItem>
                        {addresses.map((addr, index) => (
                          <MenuItem key={index + 1} value={addr}>
                            <ListItemText
                              primary={addr ? addr : labels.DROPDOWN_OTHERS}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </React.Fragment>
                ) : null}

                {districtLocal ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" color="primary">
                      {labels.SELECT_UNIT}
                    </Typography>
                    <FormControl className={props.customStyle.formControl}>
                      <Select
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        value={building}
                        onChange={selectBuildingHandleChange}
                        input={
                          <OutlinedInput
                            classes={{
                              input: props.customStyle.selectHeight,
                            }}
                          />
                        }
                      >
                        <MenuItem
                          className={classesLocal.lightGreyColor}
                          key={"address"}
                          value=""
                          disabled
                        >
                          <span className={classesLocal.lightGreyColor}>
                            {labels.PLEASE_SELECT_UNIT}      
                          </span>
                        </MenuItem>
                        <MenuItem >
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Search..."
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                              }
                            }}
                          />
                        </MenuItem>
                        {/* <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader> */}


                        {displayedOptions.map((building, index) => (
                          <MenuItem key={index + 1} value={building}>
                            <ListItemText
                              primary={
                                building ? building : labels.DROPDOWN_OTHERS
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </React.Fragment>
                ) : null}
                {districtLocal ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" color="primary">
                      {labels.SELECT_PREMISE_ADDRESS}
                    </Typography>
                    <FormControl className={props.customStyle.formControl}>
                      <Select
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        value={unitLocal}
                        onChange={selectUnitHandleChange}
                        input={
                          <OutlinedInput
                            classes={{
                              input: props.customStyle.selectHeight,
                            }}
                          />
                        }
                      >
                         <MenuItem key={"address"} value="" disabled>
                          <span className={classesLocal.lightGreyColor}>
                            {labels.PLEASE_SELECT_ADDRESS}
                          </span>
                        </MenuItem>
                        {units.map((unit, index) => (
                          <MenuItem key={index + 1} value={unit}>
                            <ListItemText
                              primary={unit ? unit : labels.DROPDOWN_OTHERS}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br /> <br />
                  </React.Fragment>
                ) : null}
                {!loading ? (
                  <div align="center">
                    <Button
                      autoFocus
                      variant="contained"
                      color="primary"
                      align="center"
                      onClick={handleClose}
                      disabled={!unitLocal}
                    >
                      {labels.SELECT_BUTTON}
                    </Button>
                  </div>
                ) : null}
              </DialogContent>
            </Dialog>
            <br /> <br />
            <Typography variant="caption" display="block" gutterBottom>
              <h6>
                <b>{labels.SELECTED_PREMISE}</b>
              </h6>
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  fullWidth
                  rows={2}
                  placeholder={labels.SELECTED_PREMISE}
                  variant="outlined"
                  color="default"
                  value={props.selectedPremise}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          
            {uniqueRadioValue === "villa" ? (
              <Grid item xs={12} sm={6} className="margin-top-20">
                <InputLabel align="left" required>
                  <b>{labels.COOLING_CAPACITY}</b>
                </InputLabel>
                <OutlinedTextFiled
                  variant="outlined"
                  id="company_id"
                  name="company_name"
                  inputProps={{
                    maxLength: coolingCapacity_length,
                  }}
                  value={props.Cooling}
                  disabled={false}
                  onChange={coolingCapacityHandleChange}
                  onBlur={coolingBlur}
                  autoComplete="off"
                  placeholder={labels.COOLING_CAPACITY_PLACEHOLDER}
                  error={buildNumberErrorlocal}
                  helperText={
                    buildNumberErrorlocal === true
                      ? labels.COOLING_CAPACITY_REQUIRED
                      : null
                  }
                />
              </Grid>
            ) : null}
            <br />
            <InputLabel align="left" >
              <h6>
                <b>{labels.ADDITIONAL_NOTES}</b>
              </h6>
            </InputLabel>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={2}
                  variant="outlined"
                  value={props.adlnotes}
                  placeholder={labels.NOTES_PLACEHOLDER}
                  inputProps={{ maxLength: placeholder_max_length }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginTop: "5vh" }}
                      >
                        {props.adlnotes != null
                          ? `${props.adlnotes.length}/${placeholder_max_length}`
                          : `0/${placeholder_max_length}`}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={describe}
                />
              </Grid>
            </Grid>
            <br />
            <div className="horizontalLine">
              <Typography
                color="primary"
                gutterBottom
                variant="subtitle1"
                component="h2"
                className="margin-top-10"
              >
                <h5>
                  <b>{labels.MAILING_ADDRESS}</b>
                </h5>
              </Typography>
            </div>{" "}
            <br />
            <Grid container direaction="row" spacing={1}>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <OutlinedTextFiled
                  variant="outlined"
                  inputProps={{
                    maxLength: mailing_address_max_length,
                  }}
                  fullWidth
                  name="unit"
                  value={unit}
                  onChange={unitHandleChange}
                  onBlur={unitBlur}
                  autoComplete="off"
                  placeholder={labels.SELECT_UNIT+"*"}
                  error={unitError}
                  helperText={
                    unitError === true ? (
                      labels.UNIT_REQUIRED
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <OutlinedTextFiled
                  variant="outlined"
                  inputProps={{
                    maxLength: mailing_address_max_length,
                  }}
                  fullWidth
                  id="registeredEmail_Id"
                  name="registeredEmail"
                  value={bname}
                  disabled={false}
                  onChange={buildingNameHandleChange}
                  onBlur={buildingNameBlur}
                  autoComplete="off"
                  placeholder={labels.BUILDING_NAME+"*"}
                  error={buildingNameRequire}
                  helperText={
                    buildingNameRequire === true ? (
                      labels.BUILDING_NAME_ERROR
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <OutlinedTextFiled
                  variant="outlined"
                  inputProps={{
                    maxLength: mailing_address_max_length,
                  }}
                  fullWidth
                  id="registeredEmail_Id"
                  name="registeredEmail"
                  value={bnum}
                  disabled={false}
                  onChange={buildNumberHandleChange}
                  onBlur={buildingNumberBlur}
                  autoComplete="off"
                  placeholder={labels.BUILDING_NUMBER+"*"}
                  error={buildingNumberRequire}
                  helperText={
                    buildingNumberRequire === true ? (
                      labels.BUILDING_NUMBER_ERROR
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container direaction="row" spacing={1}>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <OutlinedTextFiled
                  variant="outlined"
                  inputProps={{
                    maxLength: mailing_address_max_length,
                  }}
                  id="registeredEmail_Id"
                  name="registeredEmail"
                  value={address}
                  disabled={false}
                  onChange={addressHandleChange}
                  onBlur={addressBlur}
                  autoComplete="off"
                  placeholder={labels.SELECT_PREMISE_ADDRESS+"*"}
                  error={addressRequire}
                  helperText={
                    addressRequire === true ? (
                      labels.ADDRESS_ERROR
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <OutlinedTextFiled
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: mailing_address_max_length,
                  }}
                  id="registeredEmail_Id"
                  name="registeredEmail"
                  disabled={false}
                  value={district}
                  onChange={districtHandleChange}
                  onBlur={districtBlur}
                  autoComplete="off"
                  placeholder={labels.SELECT_PREMISE_DISTRICT+"*"}
                  error={districtRequire}
                  helperText={
                    districtRequire === true ? (
                      labels.DISTRICT_ERROR
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                />
              </Grid>
            </Grid>{" "}
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <OutlinedTextFiled
                variant="outlined"
                inputProps={{
                  maxLength: mailing_address_max_length,
                }}
                fullWidth
                id="registeredEmail_Id"
                name="registeredEmail"
                value={pobox}
                disabled={false}
                onChange={poBoxHandleChange}
                autoComplete="off"
                placeholder={labels.PO_BOX_PLACEHOLDER}
              />
            </Grid>
          </div>
        ) : null}
      </div>
  )
}

const DialogTitle = withStyles(styles)((DialogTitleProps) => {
    const { children, classes, onClose, ...other } = DialogTitleProps;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

export default PropertyDetails;