const BASE_URL = "https://my.qatarcool.com"; 
const OAUTH_BASE_URL = BASE_URL + "/auth/";
const ADMIN_BASE_URL = BASE_URL + "/admin/";
const ASSEST_MANAGER_URL = BASE_URL + "/assets/";
const BASE_TENANT_CODE = "QCC";
const ROCKET_CHAT_WIDGET = BASE_URL + "/assets/qatarcool-helpbot/";
const ADMIN_SERVICE_BASE_URL = BASE_URL + "/admin/admin-service";
const USAGE_SERVICE_BASE_URL = BASE_URL + "/usage/usage-service";
const COMMUNICATION_SERVICE_BASE_URL = BASE_URL + "/communication/communication-service";
const CLIENT_ID_KEYCLOCK = "qc-auth-ui";
const AUTH_UI_CLIENT_SECRET_KEYCLOCK = "d8e9a81b-5efe-46cc-b83c-d4b47248324e";
const PAYMENT_URL = "https://ap-gateway.mastercard.com/checkout/version/60/checkout.js"; 
const BILL_PAY_URL = BASE_URL+"/usage/billpay";

const constants = {
  urls: {
    BASE_URL: BASE_URL,
    AUTH_UI_BASE_URL: BASE_URL + "/login",
    LOGIN: OAUTH_BASE_URL + "login",
    ROCKET_CHAT_WIDGET,
    LOGIN_VIA_OTP: OAUTH_BASE_URL + "login/userName",
    LOGOUT: OAUTH_BASE_URL + "login/signout",
    SIGNUP: OAUTH_BASE_URL + "user/create",
    USER:OAUTH_BASE_URL+"user/",
    RESET: OAUTH_BASE_URL + "login/reset-password",
    RESETFORM: OAUTH_BASE_URL + "login/update-password",
    LOGIN_SENDOTP: OAUTH_BASE_URL + "login/otp/send",
    REFRESH_BEARER: OAUTH_BASE_URL + "login/refresh",
    GENERATE_BEARER: OAUTH_BASE_URL + "login/generate-bearer",
    VALIDATEOTP: OAUTH_BASE_URL + "otp/validate",
    LOGIN_RESENDOTP: OAUTH_BASE_URL + "otp/resend",
    SIGNUP_SENDOTP: OAUTH_BASE_URL + "user/otp/send",
    SIGNUP_VALIDATEOTP: OAUTH_BASE_URL + "user/otp/validate",
    SIGNUP_RESENDOTP: OAUTH_BASE_URL + "otp/resend",
    MAIN_APP: BASE_URL + "/",
    LABELS: ADMIN_BASE_URL + "labels/" + BASE_TENANT_CODE,
    PARAMETER_WIDGET_CONTROL:
      ADMIN_BASE_URL + "parameter-controller/" + BASE_TENANT_CODE,
    ASSEST_CODE_API: ADMIN_BASE_URL + "assets/" + BASE_TENANT_CODE + "/",
    ASSEST_URL_ENDPOINT: ASSEST_MANAGER_URL,
    ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL,
    USAGE_SERVICE_BASE_URL: USAGE_SERVICE_BASE_URL,
    PAYMENT_URL,
    RESEND_EMAIL: OAUTH_BASE_URL + "/auth-service",
    PREMISES: OAUTH_BASE_URL + "auth-service",
    BILL_PAY_PRE_PAYMENT:BILL_PAY_URL+"/prepayment-processing-bill-pay",
    BILL_PAY_POST_PAYMENT: BILL_PAY_URL+"/postpayment-processing-bill-pay",
    USERCHECK:OAUTH_BASE_URL+"user/check-username",
    NEW_USER_SEND_OTP: OAUTH_BASE_URL + "otp/newUserSendOtp",
    NEW_USER_VALIDATE_OTP: OAUTH_BASE_URL + "otp/newUserValidateOtp",
    COMMUNICATION_SERVICE_BASE_URL:COMMUNICATION_SERVICE_BASE_URL,
    ADMIN_LOG_URL: ADMIN_BASE_URL + "log/external",
    OUTAGE_STATUS_URL: OAUTH_BASE_URL + "outage/status",
    USAGE_SERVICE_URL: BASE_URL + "/usage"
  },
  payment: {
    PAYMENT_FIALED: "Payment failed.",
    QATAR_ID: "QATAR_ID",
    QID: "QID",
    PASSPORT: "PASPT"
  },
  parameters: {
    TENANT_CODE: BASE_TENANT_CODE,
  },
  recaptchaKey:{
    siteKey : "6LeJB-chAAAAAAAaNIH19PbKAg40oPJZfMMzRf9g"
  },
  confidentialClient:{
    CLIENT_ID_KEYCLOCK,
    AUTH_UI_CLIENT_SECRET_KEYCLOCK
  },
  MigrateUser:{
    URL : OAUTH_BASE_URL+"user/migrateUsers"
  },
};

export const config = constants;
