import React, { useRef, useState, useEffect } from "react"; import { Grid, Typography, Button, Table, TableRow, TableCell, TableBody, Hidden, Card, } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
// import Backlink from "../../self-help/backlink";
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from "../../app/base-style";
import { routePaths } from "../../config/route-paths";
import ReactToPrint from "react-to-print";
import { appService } from "../../../services/app.service";
import headerLogo from "../../../assests/qc-default-logo.png";
import { store } from "../../../helpers/store";
import { Link } from "react-router-dom";
import { history } from "../../../helpers/history";
import { useMediaQuery } from "react-responsive";
import "../../app/base-style.css";
import { useHistory } from "react-router-dom";
import { config } from "../../../enviroment";
import { connect, useSelector } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { adminService, commonService, usageService, } from "../../../services/index";
import { QuickPayCommonHeader } from "../../quick-pay/quick-pay-header";import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { red, green } from "@material-ui/core/colors";
import { customStyles } from "./confirmation-style";

export const useStyles1 = makeStyles((theme) =>
  createStyles({
    container: {
        minHeight: "50vh",
        backgroundColor: "white",
        borderRadius: "10px",
    }
  })
);

const GooglePayConfirmation = () => {
    const label = useSelector((state)=>state?.labels?.data?.getLabels);
    // const [labels, setlabels] = useState();
    // const [billingAddressLabel, setbillingAddressLabel] = useState()
    //   useEffect(() => {
    //     setlabels(label?.USER_MANAGEMENT)
    //     setbillingAddressLabel(label?.PAYMENT_CONFIRMATION.PAYMENT_BILLING_ADDRESS)
    // }, [label])
    const customUseStyle = useStyles1();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmation, setConfirmation] = useState('');
    const [data, setMainData] = useState();
    const location = useLocation();
    const connectionLabel = useSelector((state)=>state?.labels?.data?.getLabels?.BILL_DETAILS?.CONNECTION_DETAILS);
    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.PAYMENT_CONFIRMATION);
    const receiptLabel = useSelector((state)=>state?.labels?.data?.getLabels?.PAYMENT_RECEIPT?.PAYMENT_RECEIPT_PAY_ID);
    const labelsHide = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT?.QUICK_PAY_HIDE);
    const labelsDetails = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT?.QUICK_PAY_DETAILS);
    const { paymentData, orderDetails, transactionInfo, referenceId, deviceInfo, clientReferenceInformation, consumerAuthenticationInformation, type } = location.state || {};
    let multipleAccountDetails = "";
    const accountInformation = store.getState().meterDetails;
    if (accountInformation) {
        multipleAccountDetails = accountInformation;
    }
    const [open, setOpen] = useState(false);
    const [postPaymentProcessing, setPostPaymentProcessing] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
    const history = useHistory();
    const [labelSubTitle, setlabelSubTitle] = useState(null);
    const [paymentPara, setpaymentPara] = useState(null);
    const [error, setError] = useState(false)
    const customStyle = customStyles();
    const classes = useStyles();
    let headStyle = `tableBorder ${classes.h3Primary}`;
    const [appLogo, setAppLogo] = useState(null);
    const componentRef = useRef();
    const app_Logo = useSelector((state) => state?.appLogo);
    useEffect(() => {
        setAppLogo(app_Logo);
    }, [app_Logo]);

    const backCall = () => {
        history.push(routePaths.QUICK_PAY);
      };
    const handleClicked = () => {
        if (open === true) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };
    useEffect(() => {
        adminService.getContentData("en", "USER_MANAGEMENT").then((res) => {
            res.data.getContents.USER_MANAGEMENT.forEach((item) => {
                if (item.contentCode === "QUICK_PAY_SUBTITLE") {
                    setlabelSubTitle(item.answer);
                } else if (item.contentCode === "PAYMENT_PARAGRAPH") {
                    setpaymentPara(item.answer);
                }
            });
        });
        const processPayment = async () => {
            try {
                let body = {
                    paymentData, transactionInfo, deviceInfo, paymentInfo: { referenceId: referenceId, type, clientReferenceInformation, consumerAuthenticationInformation }
                }
                if(type === "AUTHORIZED") {
                    if(transactionInfo.source === "SERVICE_PAY") {
                        history.push({
                            pathname: routePaths.NSA_PAY_CONFIRM,
                            state: { orderDetails, transactionInfo }
                        });
                        return;
                    }
                    setPostPaymentProcessing(orderDetails);
                } else {
                    if (!paymentData) throw new Error('No payment data found.');
                    const response = await axios.post(config.urls.USAGE_SERVICE_URL + '/google-pay/process', body);
                    if (response.data) {
                        if(transactionInfo.source === "SERVICE_PAY") {
                            history.push({
                                pathname: routePaths.NSA_PAY_CONFIRM,
                                state: { response: { data: response.data }, orderDetails: response.data.orderDetails, transactionInfo }
                            });
                            return;
                        }
                        if(response.data.status == "AUTHORIZED_RISK_DECLINED") {
                            setError("Your payment transaction is declined.");
                        } else if (response.data.status == "AUTHORIZED") {
                            setMainData(response.data)
                            setPostPaymentProcessing(response.data.orderDetails);
                        } else if (response.data.flow === "CAPTURE" || response.data.flow === "PENDING") {
                            setMainData(response.data)
                            setPostPaymentProcessing(response.data.orderDetails);
                        } else {
                            setMainData(response.data)
                        }
                    }
                }
                setIsLoading(false);
            } catch (error) {
                if(transactionInfo.source === "SERVICE_PAY") {
                    history.push({
                        pathname: routePaths.NSA_PAY_CONFIRM,
                        state: { transactionInfo }
                    });
                    return;
                }
                console.error('Payment Error:', error);
                setError("Something went wrong.");
                setIsLoading(false);
            }
        };
        if(paymentData && transactionInfo) {
            window.history.replaceState({}, document.title, window.location.pathname);
            processPayment();
        } else {
            history.push({ pathname: routePaths.QUICK_PAY });
        }
    }, [paymentData]);
    
    if (!appLogo && store.getState().parametersWidgets.ParameterLookup) {
        commonService
            .getAssestUrl(
                store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
            )
            .then((resp) => {
                setAppLogo(resp);
            });
    }
    return (
    <Card style={{ width: "100%" }}>
        <div style={{width: "100%"}}>
            {isLoading || !labels ? (
                <>
                    <Grid container alignItems="center" style={{ minHeight: "50vh" }}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            Processing your payment...
                            </Typography>
                            <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            Please don't refresh the page or navigate other page.
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            ) : error || !postPaymentProcessing?.mainData  ? (
                <Grid container alignItems="center" className={customUseStyle.container}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            <b>{error}</b>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <div style={{ marginTop: isTabletOrMobile ? "75px" : "75px" }}>
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Grid item xs={2}>
                            <div>
                                {isTabletOrMobile ? (
                                    <div className="float-left">
                                        <Button
                                            className={classes.primaryColor}
                                            size="small"
                                            startIcon={
                                                <ArrowBackIosIcon fontSize="small" className="marginRight" />
                                            }
                                            onClick={backCall}
                                        >
                                            <b>Back</b>
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        className={classes.primaryColorSF}
                                        size="small"
                                        startIcon={<ArrowBackIosIcon fontSize="small" />}
                                        onClick={backCall}
                                    >
                                        <b>Back</b>
                                    </Button>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={6}>
                            <Hidden xlUp xsDown>
                                <Typography color="primary" gutterBottom variant="h6" component="h2" align="center" >
                                    <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
                                </Typography>
                            </Hidden>
                            <Hidden smUp>
                                <Grid container direction="row" justify="flex-start" className="margin-top-10" spacing={1} >
                                    <Grid item>
                                        <Typography color="primary" gutterBottom variant="h6" component="h2" align="center" >
                                            <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
                                            &nbsp;&nbsp;
                                            <Link onClick={handleClicked}>
                                                {open ? (
                                                    <span className={`dividerCenterCss ${classes.linkColor}`}>
                                                        {labelsHide} <ExpandLess />
                                                    </span>
                                                ) : (
                                                    <span className={`dividerCenterCss ${classes.linkColor}`}>
                                                        {labelsDetails} <ExpandMore />
                                                    </span>
                                                )}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid item md={1}>
                            <div>&nbsp;&nbsp;</div>
                        </Grid>
                    </Grid>
                    <Hidden xlUp xsDown>
                        {labelSubTitle && (
                            <div
                                style={{padding: "5px", textWrap: "auto"}}
                                // className={this.props.baseStyle.quaternaryColor}
                                dangerouslySetInnerHTML={{
                                    __html: labelSubTitle,
                                }}
                            ></div>
                        )}
                    </Hidden>
                    <Hidden smUp>
                        {labelSubTitle && open && (
                            <div
                                style={{padding: "5px", textWrap: "balance"}}
                                // className={this.props.baseStyle.quaternaryColor}
                                dangerouslySetInnerHTML={{
                                    __html: labelSubTitle,
                                }}
                            ></div>
                        )}
                    </Hidden>
                    <div>
                        <div className={customStyle.printDisplay}>
                            <div ref={componentRef} className={customStyle.printSize}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="space-between"
                                >
                                    <Grid item>
                                        <img
                                            height="4%"
                                            width="130%"
                                            src={appLogo ? appLogo.assetPath : headerLogo}
                                            alt={
                                                appLogo
                                                    ? labels[appLogo.fallBackLabelKey]
                                                    : labels.ASSET_LOGO_FALLBACK_TEXT
                                            }
                                            loading="lazy"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="primary"
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            align="center"
                                        >
                                            <b>{labels.PAYMENT_CONFIRMATION_PRINT_TITLE}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div>&nbsp;&nbsp;</div>
                                    </Grid>
                                </Grid>
                                <br />
                                <div className="text">
                                    <span className={customStyle.greyColor}>
                                        {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
                                        <span className={customStyle.linkColor}>
                                            <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
                                        </span>
                                    </span>
                                </div>
                                <hr />
                                {postPaymentProcessing.mainData.length > 0
                                    ? postPaymentProcessing.mainData.map((element, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <Grid container direction="row" justify="center">
                                                    <Grid item xs={12} md={6}>
                                                        <br />
                                                        <Typography className={classes.h2Primary}>
                                                            {postPaymentProcessing.mainData.length > 1 ? (
                                                                <b>
                                                                    {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                                                        `(${k + 1}/${postPaymentProcessing.mainData.length
                                                                        })`}
                                                                </b>
                                                            ) : (
                                                                <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                                                            )}
                                                        </Typography>
                                                        <br />
                                                        {postPaymentProcessing ? (
                                                            <Table size="small">
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {element.paymentInfo.input.paymentType}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {element.paymentInfo.input.creditCardInfo
                                                                        .cardType &&
                                                                        element.paymentInfo.input.creditCardInfo
                                                                            .cardNumber ? (
                                                                        <TableRow>
                                                                            <TableCell className={headStyle}>
                                                                                <b>
                                                                                    {
                                                                                        labels.PAYMENT_CONFIRMATION_INFO_CARD_DETAILS
                                                                                    }
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell className={headStyle} align="left">
                                                                                {element.paymentInfo.input.creditCardInfo
                                                                                    .cardType +
                                                                                    ":" +
                                                                                    element.paymentInfo.input.creditCardInfo
                                                                                        .cardNumber}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : null}
                                                                    {element.billingAddress &&
                                                                        <TableRow>
                                                                            <TableCell className={headStyle}>
                                                                                <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                                                            </TableCell>
                                                                            <TableCell className={headStyle} align="left">
                                                                                {element.billingAddress}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Card className={customStyle.lightblueColorPayment}>
                                                            <Typography className={classes.h3Primary}>
                                                                <b>{connectionLabel}</b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {element.head.connectionId}
                                                                <br />
                                                            </Typography>
                                                            <br />
                                                            {element.errorInformation.isInError === "true" ? null : (
                                                                <React.Fragment>
                                                                    <Typography className={classes.h3Primary}>
                                                                    <b>{receiptLabel}</b>
                                                                    </Typography>
                                                                    <Typography className={classes.h3Primary}>
                                                                        {element.paymentInfo.inputOutput && element.paymentInfo.inputOutput.paymentEventId }
                                                                    <br />
                                                                    </Typography>
                                                                    <br />
                                                                </React.Fragment>
                                                            )}
                                                            <Typography className={classes.h3Primary}>
                                                                <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {
                                                                    element.errorInformation.isInError === "true" ? (
                                                                        <span style={{ color: "red" }}>
                                                                            Failure - {element.errorInformation.errorMessage}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ color: "green" }}>
                                                                            {labels.PAYMENT_CONFIRMATION_SUCCESS}
                                                                        </span>
                                                                    )
                                                                }
                                                                <br />
                                                            </Typography>
                                                            <br />
                                                            <Typography className={classes.h3Primary}>
                                                                <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {postPaymentProcessing.mainData
                                                                    ? commonService.dateFormat(
                                                                        element.paymentInfo.input.paymentDateTime,
                                                                        "MMM DD, YYYY"
                                                                    )
                                                                    : null}
                                                            </Typography>
                                                            <br />
                                                            <Typography className={classes.h3Primary}>
                                                                <b>
                                                                    {labels.PAYMENT_CONFIRMATION_AMOUNT_PAID}
                                                                </b>
                                                            </Typography>
                                                            <Typography className={customStyle.amountTertiary}>
                                                                {postPaymentProcessing.mainData
                                                                    ? element.paymentInfo.input.currency +
                                                                    " " +
                                                                    Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                                                        minimumFractionDigits: 2
                                                                    })
                                                                    : null}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                                <hr />
                                            </React.Fragment>
                                        );
                                    })
                                    :
                                    null}
                            </div>
                        </div>
                        <div >
                            <hr />
                            <div className={customStyle.maindivPayment}>
                                {postPaymentProcessing.mainData.length > 0
                                    ? postPaymentProcessing.mainData.map((element, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <Grid container direction="row" justify="space-between" alignContent="center" >
                                                    <Grid item xs={12} md={6}>
                                                        <br />
                                                        <Typography className={classes.h2Primary}>
                                                            {postPaymentProcessing.mainData.length > 1 ? (
                                                                <b>
                                                                    {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                                                        `(${k + 1}/${postPaymentProcessing.mainData.length
                                                                        })`}
                                                                </b>
                                                            ) : (
                                                                <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                                                            )}
                                                        </Typography>
                                                        <br />
                                                        {postPaymentProcessing ? (
                                                            <Table size="small">
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {element.paymentInfo.input.paymentType}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>
                                                                                {labels.PAYMENT_CONFIRMATION_INFO_CARD_TYPE}
                                                                            </b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {
                                                                                element.paymentInfo.input.creditCardInfo
                                                                                    .cardType
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {element.paymentInfo.input.creditCardInfo
                                                                        .cardNumber && (
                                                                            <TableRow>
                                                                                <TableCell className={headStyle}>
                                                                                    <b>
                                                                                        {
                                                                                            labels.PAYMENT_CONFIRMATION_INFO_CARD_NUMBER
                                                                                        }
                                                                                    </b>
                                                                                </TableCell>
                                                                                <TableCell className={headStyle} align="left">
                                                                                    {
                                                                                        element.paymentInfo.input.creditCardInfo
                                                                                            .cardNumber
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    {element.paymentInfo.input.creditCardInfo
                                                                        .expMonth && (
                                                                            <TableRow>
                                                                                <TableCell className={headStyle}>
                                                                                    <b>
                                                                                        {
                                                                                            labels.PAYMENT_CONFIRMATION_CARD_EXPIRY_DATE
                                                                                        }
                                                                                    </b>
                                                                                </TableCell>
                                                                                <TableCell className={headStyle} align="left">
                                                                                    {element.paymentInfo.input.creditCardInfo
                                                                                        .expMonth +
                                                                                        "/" +
                                                                                        element.paymentInfo.input.creditCardInfo
                                                                                            .expYear}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    {element.billingAddress &&
                                                                        <TableRow>
                                                                            <TableCell className={headStyle}>
                                                                                <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                                                            </TableCell>
                                                                            <TableCell className={headStyle} align="left">
                                                                                {element.billingAddress}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </TableBody>
                                                                <br />
                                                            </Table>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Card className={customStyle.lightblueColorPayment}>
                                                            <Typography className={classes.h3Primary}>
                                                                <b>
                                                                    {element.head.connectionId
                                                                        ? connectionLabel
                                                                        : element.head.billId
                                                                            ? "Bill Id"
                                                                            : null}
                                                                </b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {element.head.connectionId
                                                                    ? element.head.connectionId
                                                                    : element.head.billId
                                                                        ? element.head.billId
                                                                        : null}
                                                                <br />
                                                            </Typography>
                                                            <br />
                                                            {element.errorInformation.isInError === "true" ? null : (
                                                                <React.Fragment>
                                                                    <Typography className={classes.h3Primary}>
                                                                    <b>{receiptLabel}</b>
                                                                    </Typography>
                                                                    <Typography className={classes.h3Primary}>
                                                                        {element.paymentInfo.inputOutput && element.paymentInfo.inputOutput.paymentEventId }
                                                                    <br />
                                                                    </Typography>
                                                                    <br />
                                                                </React.Fragment>
                                                            )}
                                                            <Typography className={classes.h3Primary}>
                                                                <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {
                                                                    element.errorInformation.isInError === "true" ? (
                                                                        <span style={{ color: "red" }}>
                                                                            Failure - {element.errorInformation.errorMessage}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ color: "green" }}>
                                                                            {labels.PAYMENT_CONFIRMATION_SUCCESS}
                                                                        </span>
                                                                    )
                                                                }
                                                                <br />
                                                            </Typography>
                                                            <br />
                                                            <Typography className={classes.h3Primary}>
                                                                <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                                                            </Typography>
                                                            <Typography className={classes.h3Primary}>
                                                                {postPaymentProcessing.mainData
                                                                    ? commonService.dateFormat(
                                                                        element.paymentInfo.input.paymentDateTime,
                                                                        "MMM DD, YYYY"
                                                                    )
                                                                    : null}
                                                            </Typography>
                                                            <br />
                                                            <Typography className={classes.h3Primary}>
                                                                <b>
                                                                    {labels.PAYMENT_CONFIRMATION_AMOUNT_PAID}
                                                                </b>
                                                            </Typography>
                                                            <Typography
                                                                className={customStyle.amountTertiary}
                                                                variant="h5"
                                                            >
                                                                {postPaymentProcessing.mainData
                                                                    ? element.paymentInfo.input.currency +
                                                                    " " +
                                                                    Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                                                        minimumFractionDigits: 2
                                                                    })
                                                                    : null}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row-reverse" justify="space-between" alignContent="center" >
                                                    <Grid item xs={12} md={12} >
                                                        <hr />
                                                        {/* {lastContent ? (
                                                        <Typography
                                                            className={customStyle.greyColor}
                                                            dangerouslySetInnerHTML={{
                                                                __html: lastContent,
                                                            }}
                                                        />
                                                    ) : null} */}
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })
                                    : null}
                            </div>
                            <br />
                        </div>
                    </div>

                </div>


            )}
        </div>
    </Card>
    );
};


const connectedApp = connect(null, { })(GooglePayConfirmation);
export { connectedApp as GooglePayConfirmation };
