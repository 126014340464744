import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { history } from "../../../helpers/history";
import { config } from '../../../enviroment';
import { routePaths } from '../../config/route-paths';
import { Button, Card } from "@material-ui/core"

const GPay3DSStepUpResult = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [transactionInfo, setTransactionInfo] = useState();
  const [paymentData, setPaymentData] = useState();
  const [transactionId, setTransactionId] = useState();
  const getTransactionId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const transactionId = queryParams.get('TransactionId');
    const MD = queryParams.get('MD');
    console.log("Query params", queryParams)
    console.log("Query params", transactionId, MD)
    if (!queryParams) {
      history.push({ pathname: routePaths.LOGIN, });
    }
    if (!transactionId) {
      setError("We couldn't authenticate. Required parameters are missing!");
      setLoading(false);
      return;
    }
    setTransactionId(transactionId);
    window.history.replaceState(null, '', window.location.pathname);
    return { transactionId, MD }
  }
  useEffect(() => {
    let params = getTransactionId();
    const gPayData = localStorage.getItem("temp-g-data") && JSON.parse(localStorage.getItem("temp-g-data"));
    if (!gPayData?.transactionInfo || !gPayData?.paymentData) {
      setError("Something went wrong, Please try again");
      setLoading(false);
      return;
    }
    setTransactionInfo(gPayData.transactionInfo);
    setPaymentData(gPayData.paymentData);
    localStorage.removeItem("temp-g-data");
    validateResult(gPayData.paymentData, gPayData.transactionInfo, params.transactionId, gPayData.deviceInfo, gPayData.paymentInfo)
  }, []);
  const validateResult = async (paymentData, transactionInfo, transactionId, deviceInfo, paymentInfo) => {
    try {
      const response = await axios.post(config.urls.USAGE_SERVICE_URL + '/g-pay/validate', { paymentData, transactionInfo, transactionId, deviceInfo, paymentInfo });
      const { clientReferenceInformation, consumerAuthenticationInformation, orderDetails } = response.data;
      setLoading(false);
      if (orderDetails) {
        history.push({
          pathname: routePaths.GOOGLE_PAY_CONFIRMATION,
          state: { type: "AUTHORIZED", orderDetails, paymentData, transactionInfo, deviceInfo }
        });
      }
      if (consumerAuthenticationInformation) {
        const veres = consumerAuthenticationInformation?.paresStatus;
        if (veres == "Y") {
          history.push({
            pathname: routePaths.GOOGLE_PAY_CONFIRMATION,
            state: { type: "STEP_UP", paymentData, transactionInfo, deviceInfo, clientReferenceInformation: clientReferenceInformation, consumerAuthenticationInformation: consumerAuthenticationInformation }
          });
        } else if (veres == "U") {
          setError("Authentication is not available, Please retry the transaction.");
        } else if (veres == "N") {
          setError("Your card issuer cannot authenticate this card. Please select another card or form of payment to complete the payment");
        } else {
          setError("Your card issuer cannot authenticate this card. Please select another card or form of payment to complete the payment");
        }
      } else {
        setError("We couldn't authenticate.");
      }
    } catch (error) {
      setError("Something went wrong, Please try again");
      setLoading(false);
    }
  }
  const handleRetry = () => {
    history.push({
          pathname: transactionInfo.source === "SERVICE_PAY" ? routePaths.NSA : routePaths.VIEW_BALANCE
    });
  }
  return (
    <Card style={{ width: "100%", height: "100vh" }}>
      <div style={{ textAlign: 'center', padding: '20px', marginTop: "5rem", width: "100%" }}>
        {loading && <h2>Authenticating...</h2>}
        {statusMessage && !loading && (
          <div style={{ color: 'green', fontSize: '18px', fontWeight: 'bold' }}>
            <p>{statusMessage}</p>
          </div>
        )}
        {error && !loading && (
          <div style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', marginTop: "1rem" }}>
            <p>{error}</p>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleRetry}
            >
              Retry
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default GPay3DSStepUpResult;
