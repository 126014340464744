import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Login } from "../login/login";
import { Register } from "../sign-up/register";
import { Reset } from "../reset-password/reset-request";
import { ResetForm } from "../reset-password/reset-form";
import { routePaths } from "../config/route-paths";
import { AuthOTP } from "../two-factor/auth-otp";
import { LandingComponent } from "../landing/landing-component";
import { LoginViaOTP } from "../login/login-via-otp";
import QuickPay from "../quick-pay/quick-pay";
import ViewBalance from "../quick-pay/balance";
import { QuickPayPayment } from "../quick-pay/payment-screen/payment";
import {TermsCondition} from "../terms-condition/terms-condition";
import RetryPage from "../retry/retry-component";
// import NSA from "../new-service-agreement/service-agreement";
import NSA from "../new-service-agreement-with-upfront-payment/service-agreement";
import BillPay from "../quick-pay/bill-pay/bill-pay";
import Payment from "../new-service-agreement-with-upfront-payment/new-service-agreement/view/_confirm-service-agreement"
import Thankyou from "../new-service-agreement-with-upfront-payment/new-service-agreement/view/_thankyou-screen";
import  MigrateUser  from "../migrate-user/migrate-user";
import { GooglePayConfirmation } from "../common/confirmation/google-pay-confirmation.js";
import GooglePayProcess from "../common/transaction/google-pay-process.js";
import GPay3DSRedirectHandler from "../common/transaction/google-pay-3ds-redirect.js";
import GPay3DSStepUpResult from "../common/transaction/google-pay-3ds-step-up-result.js"
const Routes = () => {
  return (
    <Switch>
      <Route exact path={routePaths.LOGIN} component={Login} />
      <Route exact path={routePaths.REGISTER} component={Register} />
      <Route exact path={routePaths.RESET_REQUEST} component={Reset} />
      <Route exact path={routePaths.RESET_FORM} component={ResetForm} />
      <Route exact path={routePaths.AUTH_OTP} component={AuthOTP} />
      <Route exact path={routePaths.LANDING} component={LandingComponent} />
      <Route path={routePaths.LOGIN_VIA_OTP} component={LoginViaOTP} />
      <Route path={routePaths.QUICK_PAY} component={QuickPay} />
      <Route path={routePaths.MIGRATE_USER} component={MigrateUser} />
      <Route path={routePaths.BILL_PAY} component={BillPay} />
      <Route path={routePaths.VIEW_BALANCE} component={ViewBalance} />
      <Route path={routePaths.PAYMENT} component={QuickPayPayment} />
      <Route path={routePaths.PAYMENT_BILLPAY} component={QuickPayPayment} />
      <Route path={routePaths.TERMS_CONDITION} component={TermsCondition} />
      <Route path={routePaths.RETRY} component={RetryPage} />
      <Route path={routePaths.NSA} component={NSA} />
      <Route path={routePaths.NSA_PAY} component={Payment} />
      <Route path={routePaths.NSA_PAY_CONFIRM} component={Thankyou} />
      <Route path={routePaths.GOOGLE_PAY_CONFIRMATION} component={GooglePayConfirmation} />
      <Route path={routePaths.GOOGLE_PAY_PROCESS} component={GooglePayProcess} />
      <Route path={routePaths.GOOGLE_PAY_3DS_RETURN_URL} component={GPay3DSRedirectHandler} />
      <Route path={routePaths.GOOGLE_PAY_3DS_RESULT_URL} component={GPay3DSStepUpResult} />
      <Redirect from="*" to={routePaths.LOGIN} />
    </Switch>
  );
};

export default Routes;
