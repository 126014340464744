import { config } from "../../../../enviroment";
import axios from "axios";


export const paymentService = {
  prePaymentProcessing,
  postPaymentProcessingDebitPay,
  postPaymentProcessingCreditPay,
  postPaymentProcessing,
};

function generateGraphQLQuery(requestInput) {
  let requestInputFields = "";

  for (const [key, value] of Object.entries(requestInput)) {
    const escapedValue =
      typeof value === "string" ? value.replace(/"/g, '\\"') : value;
    requestInputFields += `${key}: "${escapedValue}",\n`;
  }

  let APIquery = `
    query {
      postPaymentProcessing (
        input: {
          RequestInput: {
            ${requestInputFields}
          }
        }
      )
    }
  `;

  return APIquery;
}
async function prePaymentProcessing(paymentType, dataObject, orderId) {
    let totalSelAmount=Number(localStorage.getItem("depositeAmount"));
    let connectionId = localStorage.getItem("connectionId");
    let emailId = localStorage.getItem("email")
    let tempConn = [{ConnectionId:connectionId,Amount:totalSelAmount}]
    tempConn = JSON.stringify(tempConn).replace(/"([^"]+)":/g, "$1:");
    let newJsonData =  JSON.stringify(dataObject).replace(/"([^"]+)":/g, "$1:");
    let firstName = localStorage.getItem("firstName");
    if (!firstName || firstName == "undefined") {
      firstName = "";
    }
    let lastName = localStorage.getItem("lastName");
    if (!lastName || lastName == "undefined") {
      lastName = "";
    }
    let mobileNo = localStorage.getItem("mobileNo");
    if (!mobileNo || mobileNo == "undefined") {
       mobileNo = "";
    }
    let APIquery =
      `query {
      prePaymentProcessing(
        input: {
          RequestInput:{
          isNewService: "true"
          Amount: ` +
          totalSelAmount +
      `
         source:"SERVICE_PAY"
          PaymentType:"` +
      paymentType +
      `"
          tenantCode: "` +
      config.parameters.TENANT_CODE +
      `"
          Connections: ` +
          tempConn +
      `
      primaryEmail:"` +
        emailId +
      `"    
        firstName: "` +
    firstName +
    `"
      lastName: "` +
    lastName +
    `" 
      orderId: "` +
      orderId +
    `" 
      formJson: ` +
      newJsonData + 
      `
        }
      }
      ) 
    }`;
    return new Promise((resolve, reject) => {
      axios({
        url: config.urls.USAGE_SERVICE_BASE_URL,
        method: "post",
        data: {
          query: APIquery,
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }


  
async function postPaymentProcessingDebitPay(
    paymentType,
    amount,
    bankId,
    CurrencyCode,
    responseDate,
    lang,
    MerchantID,
    MerchantModuleSessionID,
    PUN,
    SecureHash,
    Response_ConfirmationID,
    Response_Status,
    Response_StatusMessage,
    Response_AcquirerID,
    Response_CardNumber,
    Response_CardExpiryDate,
    Response_CardHolderName,
    Response_AgentID,
  ) {
    let tenantCode = config.parameters.TENANT_CODE;
    let totalSelAmount=Number(localStorage.getItem("depositeAmount"));
    let uniqueId=localStorage.getItem("connectionId")
    let lead=localStorage.getItem("lead")
    let tempConn = [{ConnectionId:uniqueId,Amount:totalSelAmount}]
    tempConn = JSON.stringify(tempConn).replace(/"([^"]+)":/g, "$1:");
    let customerName = localStorage.getItem("userName");
    let mobileNo = localStorage.getItem("mobile");
    let emailId = localStorage.getItem("email");
    let APIquery =
      `query {
        postPaymentProcessing (
          input:
          {
            RequestInput:{
              PaymentType: "` +
      paymentType +
      `"
              Amount:"` +
      amount +
      `"
              CurrencyCode:"` +
      CurrencyCode +
      `"
              PUN:"` +
      PUN +
      `"
              EZConnectResponseDate:"` +
      responseDate +
      `"
            isNewService: "true"
              ConfirmationID:"` +
      Response_ConfirmationID +
      `"
              MerchantModuleSessionID:"` +
      MerchantModuleSessionID +
      `"
              Status:"` +
      Response_Status +
      `"
              StatusMessage:"` +
      Response_StatusMessage +
      `"
              MerchantID:"` +
      MerchantID +
      `"
              BankID:"` +
      bankId +
      `"
              Lang:"` +
      lang +
      `"
              AcquirerID:"` +
      Response_AcquirerID +
      `"
              ItemID:""
              CardNumber:"` +
      Response_CardNumber +
      `"
              CardExpiryDate:"` +
      Response_CardExpiryDate +
      `"
              CardHolderName:"` +
      Response_CardHolderName +
      `"
              AgentID:"` +
      Response_AgentID +
      `"
              SecureHash:"` +
      SecureHash +
      `"
              
              userSessionId:"",
              tenantCode:"` +
      tenantCode +
      `",
              userName:"` +
     customerName +
      `" ,
              primaryEmail:"` +
       emailId +
      `",
              propertyCode:"",
              MobileNumber:"` +
      mobileNo +
      `",
              languageCode:"` +
      lang +
      `",
              lead:"` +
      lead +
      `",
              Connections:` +
    tempConn +
      `       source:"SERVICE_PAY"
  
              }
          })
      }`;
    return new Promise((resolve, reject) => {
      axios({
        url: config.urls.USAGE_SERVICE_BASE_URL,
        method: "post",
        data: {
          query: APIquery,
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }




async function postPaymentProcessingCreditPay(payment_Type,resultIndicator,languageCode) {
    let tenantCode = config.parameters.TENANT_CODE;
    let totalSelAmount=Number(localStorage.getItem("depositeAmount"));
    let uniqueId=localStorage.getItem("connectionId")
    let lead=localStorage.getItem("lead")
    let tempConn = [{ConnectionId:uniqueId,Amount:totalSelAmount}]
    tempConn = JSON.stringify(tempConn).replace(/"([^"]+)":/g, "$1:");
    let userName= localStorage.getItem("userName");
    let primaryEmail = localStorage.getItem("email");
    let MobileNumber = localStorage.getItem("mobile");
    let PaymentType = payment_Type;
    let vpc_OrderId=localStorage.getItem("orderId");
    let amount=totalSelAmount;

    let APIquery =
      `query {
        postPaymentProcessing (
          input:
          {
            RequestInput:{
              PaymentType: "` +
              PaymentType +
      `"
              Amount: ` +
                amount +
      `
         userSessionId:"",
            tenantCode:"` +
            tenantCode +
      `",
              userName:"` +
              userName +
      `" ,
              primaryEmail:"` +
            primaryEmail +
      `",
              propertyCode:"",
              MobileNumber:"` +
              MobileNumber +
      `",
              languageCode:"` +
              languageCode +
      `",
              lead:"` +
              lead +
      `",
              Connections:` +
              tempConn +
      `       
            source:"SERVICE_PAY"
            resultIndicator:"` +
            resultIndicator +
      `"
            vpc_OrderId:"`+
            vpc_OrderId+
    `"
            isNewService:"true"
              }
          })
      }`;
    return new Promise((resolve, reject) => {
      axios({
        url: config.urls.USAGE_SERVICE_BASE_URL,
        method: "post",
        data: {
          query: APIquery,
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async function postPaymentProcessing(
    paymentType,
    RequestInput
  ) {
    let tenantCode = config.parameters.TENANT_CODE;
    let totalSelAmount = Number(localStorage.getItem("depositeAmount"));
    let uniqueId = localStorage.getItem("connectionId");
    let lead = localStorage.getItem("lead");
    let tempConn = [{ ConnectionId: uniqueId, Amount: totalSelAmount }];
    tempConn = JSON.stringify(tempConn).replace(/"([^"]+)":/g, "$1:");
    let userName = localStorage.getItem("userName");
    let primaryEmail = localStorage.getItem("email");
    let MobileNumber = localStorage.getItem("mobile");
    let PaymentType = paymentType;
    const additionalFields = {
      userName: userName,
      primaryEmail: primaryEmail,
      propertyCode: "",
      userSessionId: "",
      MobileNumber: MobileNumber,
      source: "SERVICE_PAY",
      PaymentType: PaymentType,
      tenantCode: tenantCode,
      Connections: tempConn,
      lead: lead,
      isNewService: "true",
    };
    Object.assign(RequestInput, additionalFields);
    let APIquery = generateGraphQLQuery(RequestInput);
  
    return new Promise((resolve, reject) => {
      axios({
        url: config.urls.USAGE_SERVICE_BASE_URL,
        method: "post",
        data: {
          query: APIquery,
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  