import React, { useState, useEffect, useRef } from 'react';
import GooglePayButton from '@google-pay/button-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { history } from "../../../helpers/history";
import { config } from "../../../enviroment";
import { store } from "../../../helpers/store";
import { routePaths } from '../../config/route-paths';
// import Backlink from "../../self-help/backlink";
import { Grid, Typography, Button, Card, } from "@material-ui/core";
import DeviceFingerPrint from './device-fingerprint';
import { fingerPrintSessionId, setFingerPrintId } from './fingerPrintId';

function GooglePayProcess({ state }) {
  console.log(state);
  const location = useLocation();
  const { paymentData, transactionInfo, gatewayConfig } = location.state || {};
  const [deviceDataCollectionCompleted, setDeviceDataCollectionCompleted] = useState(false);
  const [enrollmentCheckStarted, setEnrollmentCheckStarted] = useState(false);
  const timerIdRef = useRef(null);
  const deviceDataListenerRef = useRef(null);
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if(paymentData && transactionInfo && gatewayConfig?.orgId) {
        console.log("Procced")
        window.history.replaceState({}, document.title, window.location.pathname);
        setLoading(true);
        let fingerPrint = getFingerPrintSessionId();
        setFingerPrintId(fingerPrint)
        handleLoadPaymentData(paymentData, fingerPrint);
    } else {
        history.push({
          pathname: transactionInfo.source === "SERVICE_PAY" ? routePaths.NSA : routePaths.QUICK_PAY
        });
    }
    // CRITICAL: Cleanup function to remove listener on unmount
    return () => {
        removeListener()
        cleanupStepUpElements();
        // window.removeEventListener("message", deviceDataListener);
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
          timerIdRef.current = null;
          console.log("Timeout cleared")
        }
      };
  }, []);
  const getFingerPrintSessionId = () => {
    const timestamp = new Date().getTime();
    const randomStr = Math.random().toString(36).substring(2, 12);    ;
    return `${timestamp}${randomStr}`;
  }
  const handleLoadPaymentData = async (paymentData, fingerPrint) => {
    try {
      const response = await axios.post(config.urls.USAGE_SERVICE_URL + '/g-pay/setup', { paymentData, transactionInfo });
      const { deviceDataCollectionUrl, jwt, referenceId, orderId } = response.data;
      transactionInfo.orderId = orderId;
      registerListener(referenceId, paymentData);
      initiateDeviceDataCollection(deviceDataCollectionUrl, jwt);
      timerIdRef.current = setTimeout(() => {
        console.log("Device data collection timeout")
        // Proceed with fallback only if device data collection is not completed
        if (!deviceDataCollectionCompleted) {
          console.log("Device data collection timeout, proceeding with fallback enrollment check.");
          performEnrollmentCheck(referenceId, paymentData, true); // fallback mode
        }
      }, 10000);
    } catch (error) {
      console.error('Error initiating payer authentication:', error);
      setLoading(false);
      setMessage("An unexpected issue occurred while authenticating. Please try again.")
    }
  };
  const registerListener = (referenceId, paymentData) => {
    removeListener();
    deviceDataListenerRef.current = deviceDataListener(referenceId, paymentData);
    window.addEventListener("message", deviceDataListenerRef.current);
  };
  const removeListener = () => {
    console.log("removing message listener")
    window.removeEventListener("message", deviceDataListener);
    if (deviceDataListenerRef.current) {
      window.removeEventListener("message", deviceDataListenerRef.current);
      deviceDataListenerRef.current = null;
    }
  };
  const deviceDataListener = (referenceId, paymentData) => (event) => {
    if (
      event.origin === "https://centinelapistag.cardinalcommerce.com" ||
      event.origin === "https://centinelapi.cardinalcommerce.com"
    ) {
      if (deviceDataCollectionCompleted) return;
      try {
        const data = JSON.parse(event.data);
        console.log("Device Data Collection Response (raw):", event.data);
        if (data.MessageType === "profile.completed" && data.Status) {
          setDeviceDataCollectionCompleted(true);
          performEnrollmentCheck(referenceId, paymentData, false); // normal mode
        } else {
          console.error("Device data collection failed or returned unexpected status:", data);
          performEnrollmentCheck(referenceId, paymentData, true); // fallback mode
        }
      } catch (error) {
        console.error("Error parsing event data:", error);
        performEnrollmentCheck(referenceId, paymentData, true); // fallback mode
      }
    }
  };
  const initiateDeviceDataCollection = (deviceDataCollectionUrl, jwt, referenceId, paymentData) => {
    createDeviceCollectionForm(deviceDataCollectionUrl, jwt);
  };
  const createDeviceCollectionForm = (deviceDataCollectionUrl, jwt) => {
    const iframe = document.createElement("iframe");
    iframe.id = "cardinal_collection_iframe";
    iframe.name = "collectionIframe";
    iframe.height = "10";
    iframe.width = "10";
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const form = document.createElement("form");
    form.id = "cardinal_collection_form";
    form.method = "POST";
    form.target = "collectionIframe";
    form.action = deviceDataCollectionUrl;

    const input = document.createElement("input");
    input.id = "cardinal_collection_form_input"
    input.type = "hidden";
    input.name = "JWT";
    input.value = jwt;
    form.appendChild(input);

    document.body.appendChild(form);

    form.submit();
  };
  const cleanupDynamicElements = () => {
    const iframe = document.getElementById("cardinal_collection_iframe");
    const form = document.getElementById("cardinal_collection_form");
    if (iframe) document.body.removeChild(iframe);
    if (form) document.body.removeChild(form);
    console.log("Dynamic form and iframe cleaned up.");
  };
  const cleanupStepUpElements = () => {
    const iframe = document.getElementById("step-up-iframe");
    const form = document.getElementById("step-up-form");
    if (iframe) {
      document.body.removeChild(iframe);
    }
    if (form) {
      document.body.removeChild(form);
    }
    console.log("Step Up form and iframe cleaned up.");
  };
  async function performEnrollmentCheck(referenceId, paymentData, isFallback) {
    console.log("Clearing Timeout")
    console.log("enrollment started?",enrollmentCheckStarted)
    clearTimeout(timerIdRef.current);
    timerIdRef.current = null;
    if (enrollmentCheckStarted) {
      console.log("Enrollment process already started. Skipping enrollment.");
      return;
    }
    setEnrollmentCheckStarted(true);
    removeListener();
    cleanupDynamicElements();
    try {
      let deviceInfo = gatherDeviceInfo();
      let paymentInfo = {
        setup: { referenceId }
      }
      const requestData = isFallback
        ? { paymentData, referenceId, transactionInfo, deviceInfo: deviceInfo, paymentInfo }
        : { paymentData, referenceId, transactionInfo, deviceInfo: deviceInfo, paymentInfo };
      const enrollmentResponse = await axios.post(config.urls.USAGE_SERVICE_URL + '/g-pay/check-enrollment', requestData);
      handleEnrollmentResponse(enrollmentResponse.data, referenceId, deviceInfo);
    } catch (error) {
      console.error("Error during enrollment check:", error);
      // alert("An error occurred during enrollment. Please try again or contact support.");
      setLoading(false);
      setMessage("An unexpected issue occurred while authenticating. Please try again.")
    }
  }
  // Handle enrollment check response
  async function handleEnrollmentResponse(data, referenceId, deviceInfo) {
    const { clientReferenceInformation, consumerAuthenticationInformation, orderDetails } = data;
    const veres = consumerAuthenticationInformation?.veresEnrolled;
    const pares = consumerAuthenticationInformation?.paresStatus;
    const cardholderMessage = consumerAuthenticationInformation?.cardholderMessage;
    // data.status and data.message
    const paymentInfo = { referenceId: referenceId, type:"STEP_IN", clientReferenceInformation, consumerAuthenticationInformation }
    setLoading(false);
    if (data.status === "AUTHORIZED") {
      history.push({
        pathname: routePaths.GOOGLE_PAY_CONFIRMATION,
        state: { type: "AUTHORIZED", orderDetails, referenceId, paymentData, transactionInfo, deviceInfo, clientReferenceInformation: data.clientReferenceInformation, consumerAuthenticationInformation: data.consumerAuthenticationInformation }
      });
    } else if (veres === 'Y' && pares === 'Y') {
      // alert("Authentication was successful. You may proceed with the payment.");
      history.push({
        pathname: routePaths.GOOGLE_PAY_CONFIRMATION,
        state: { type: "FRICTIONLESS", referenceId, paymentData, transactionInfo, deviceInfo, clientReferenceInformation: data.clientReferenceInformation, consumerAuthenticationInformation: data.consumerAuthenticationInformation }
      });
    } else if (veres === 'Y' && pares === 'C') {
      // PENDING_AUTHENTICATION (Step-Up required)
      handlePendingAuthentication(data, referenceId, deviceInfo, paymentInfo);
      // alert("Pending authentication, step-up challenge is required.");
    } else if (veres === 'Y' && pares === 'A') {
      // Attempts Stand-in Frictionless Authentication (Liability shift protected)
      // alert("Stand-in authentication completed. Proceeding with authorization.");
      history.push({
        pathname: routePaths.GOOGLE_PAY_CONFIRMATION,
        state: { type: "STANDIN_FRICTIONLESS", referenceId, paymentData, transactionInfo, deviceInfo, clientReferenceInformation: data.clientReferenceInformation, consumerAuthenticationInformation }
      });
  
    } else if (veres === 'Y' && pares === 'U') {
      // Unavailable Frictionless Authentication (No liability shift protection)
      setMessage(cardholderMessage || "Authentication is currently unavailable, Please try later or use another payment card or method.")
      // alert("Authentication is currently unavailable. Proceed with authorization at your own risk.");
  
    } else if (veres === 'Y' && pares === 'N') {
      // AUTHENTICATION_FAILED
      setMessage(cardholderMessage || "Authentication failed. Please try another payment method.")
      // alert(cardholderMessage || "Authentication failed. Please try another payment method.");
  
    } else if (veres === 'Y' && pares === 'R') {
      // Rejected Frictionless Authentication
      setMessage(cardholderMessage || "Authentication was rejected by the issuing bank. Please use another payment method.")
      // alert(cardholderMessage || "Authentication was rejected by the issuing bank. Please use another payment method.");
  
    } else if (veres === 'B' || veres === 'U') {
      // Card Not Enrolled
      setMessage("The card is not enrolled in authentication programs.")
      // alert("This card is not enrolled in security programs. Please consider using a different card");
    
    } else if (data.status === "PENDING_AUTHENTICATION" && consumerAuthenticationInformation?.stepUpUrl) {
      handlePendingAuthentication(data, referenceId, deviceInfo, paymentInfo);
    } else {
      // Default case for unexpected status
      setMessage("An unexpected issue occurred while authenticating. Please try again.")
      // alert("An unexpected issue occurred. Please try again.");
      console.error("Unexpected enrollment status:", data);
    }
  }
  async function handlePendingAuthentication(enrollmentResponse, referenceId, deviceInfo, paymentInfo) {
    // Check if step-up authentication is required
    // if (!enrollmentResponse.consumerAuthenticationInformation || !enrollmentResponse.consumerAuthenticationInformation.stepUpUrl) {
    //     console.log("Frictionless authentication detected. No step-up iframe required.");
    //     return; // Exit if no step-up URL is provided (frictionless flow)
    // }
    const { stepUpUrl, accessToken, challengeWindowSize, merchantData, returnUrl } = enrollmentResponse.consumerAuthenticationInformation;
    if (!stepUpUrl || !accessToken) {
        console.error("Step-up authentication data is incomplete. Cannot proceed with iframe setup.");
        setMessage("An unexpected issue occurred. Please try again.")
        // alert("Step-up authentication data is incomplete. Cannot proceed with iframe setup.");
        return; // Exit if critical information is missing
    }
    const iframeDimensions = {
        "01": { width: 250, height: 400 },
        "02": { width: 390, height: 400 },
        "03": { width: 500, height: 600 },
        "04": { width: 600, height: 400 },
        "05": { width: window.innerWidth, height: window.innerHeight } // Full screen
    };
    // alert("creating step up iframe")
    const { width, height } = iframeDimensions[challengeWindowSize] || iframeDimensions["03"]; // Default to "03" if unspecified
    const iframe = document.createElement("iframe");
    iframe.name = "step-up-iframe";
    iframe.width = width;
    iframe.height = height;
    iframe.style.border = "none";
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    iframe.id = "step-up-iframe";

    function applyStylesBasedOnBreakpoint() {
      if (mediaQuery.matches) {
        iframe.style.marginLeft = "0";
      } else {
        iframe.style.marginLeft = "20vw"; // Reset for larger screens
      }
    }
    applyStylesBasedOnBreakpoint();

    const form = document.createElement("form");
    form.id = "step-up-form";
    form.target = "step-up-iframe";
    form.method = "post";
    form.action = stepUpUrl;

    const jwtInput = document.createElement("input");
    jwtInput.type = "hidden";
    jwtInput.name = "JWT";
    jwtInput.value = accessToken;
    form.appendChild(jwtInput);

    const mdInput = document.createElement("input");
    mdInput.type = "hidden";
    mdInput.name = "MD";
    mdInput.value = transactionInfo.source;
    form.appendChild(mdInput);

    document.body.appendChild(iframe);
    document.body.appendChild(form);
    localStorage.setItem("temp-g-data",JSON.stringify({transactionInfo,paymentData,deviceInfo,paymentInfo}))
    form.submit();

    // Event listener to handle return to the returnUrl after challenge completion
    // iframe.addEventListener("load", function() {
    //     if (iframe.contentWindow.location.href.includes(returnUrl)) {
    //         // Handle successful redirection (close iframe, log, etc.)
    //         document.body.removeChild(form);
    //         document.body.removeChild(iframe);
    //         console.log("Authentication challenge completed. Proceeding with next steps.");
    //     }
    // });
  }
  function gatherDeviceInfo() {
    return {
      httpAcceptBrowserValue: navigator.userAgent,
      httpAcceptContent: document.contentType,
      httpBrowserColorDepth: window.screen.colorDepth,
      httpBrowserJavaEnabled: typeof navigator.javaEnabled === 'function' ? navigator.javaEnabled() : false,
      httpBrowserJavaScriptEnabled: true,
      httpBrowserLanguage: navigator.language,
      httpBrowserScreenHeight: window.screen.height,
      httpBrowserScreenWidth: window.screen.width,
      httpBrowserTimeDifference: new Date().getTimezoneOffset(),
      userAgentBrowserValue: navigator.userAgent,
      deviceFingerPrint: fingerPrintSessionId
    };
  }
  const handleRetry = () => {
    history.push({
      pathname: transactionInfo.source === "SERVICE_PAY" ? routePaths.NSA : routePaths.VIEW_BALANCE
    });
  }
  return (
    <>
      {
        fingerPrintSessionId && <DeviceFingerPrint gatewayConfig={gatewayConfig} sessionId={fingerPrintSessionId}/>
      }
      <Card style={{ width: "100%", height: "100vh"}}>
        {
          loading ? <>
            <Grid container alignItems="center" style={{ minHeight: "50vh" }}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                  Authenticating your payment...
                </Typography>
                <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                  Please don't refresh the page or navigate other page.
                </Typography>
              </Grid>
            </Grid>
          </> :
            <>
              <Grid item lg={4} md={2} sm={1}>
                {/* <Backlink backToHome={true} /> */}
              </Grid>
              {message &&
                <Grid container alignItems="center" justify="center" alignContent="center" style={{ minHeight: "50vh" }}>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography variant='subtitle1' style={{ textAlign: "center", color: "red", fontWeight: 'bold' }}>
                      {message}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleRetry}
                    >
                      Retry
                    </Button>
                  </Grid>
                </Grid>}
            </>
        }
      </Card>

    </>
  );
}

export default GooglePayProcess;
