export const routePaths = {
  LOGIN: "/login", //home page of application when user is logged out
  REGISTER: "/login/sign-up", //signup page
  RESET_REQUEST: "/reset-request", //reset request
  RESET_FORM: "/reset-form/:token", // reset final form
  AUTH_OTP: "/verify-otp" ,//2-factor Authentication for login
  LANDING : "/landing", //landing page (reuseable)
  LOGIN_VIA_OTP: "/login/login-via-otp" ,//login via otp page
  QUICK_PAY:"/path/quick-pay",
  VIEW_BALANCE:"/view-balance",
  PAYMENT:"/path/payment",
  PAYMENT_BILLPAY:"/path/payment/billpay",  
  TERMS_CONDITION:"/terms-condition",
  RETRY: "/service-unavailable",
  NSA:"/path/new-service-agreement",
  BILL_PAY:"/path/bill-pay",
  NSA_PAY:"/new-user-pay-initiate",
  // NSA_PAY:"/new-user-pay-confirm",
  NSA_PAY_CONFIRM:"/new-user-confirmation",
  MIGRATE_USER:"/path/migrate-user",
  GOOGLE_PAY_CONFIRMATION: "/path/confirmation",
  GOOGLE_PAY_PROCESS: "/path/gpay-process",
  GOOGLE_PAY_3DS_RETURN_URL: "/path/gpay-3ds",
  GOOGLE_PAY_3DS_RESULT_URL: "/path/gpay-3ds-result",
};
