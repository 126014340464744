import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { Button, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { customStyles } from "./_new-style";
import {useStyles} from "../../../app/base-style";
import { useSelector } from 'react-redux';
import { grey500 } from 'material-ui/styles/colors';
import { history } from "../../../../helpers/history";
import { routePaths } from "../../../config/route-paths";
import {config} from "../../../../enviroment";
import {
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
  } from "@material-ui/core";
import { CircularDialog } from "../../../quick-pay/dialog-style";
import { usageService } from '../../../../services';
import { adminService } from '../../../../services';
import HostedCheckout from '../../../quick-pay/credit-card/credit-card-hosted-checkout';
import { paymentService } from '../service/payment-service';
import Alert from "@material-ui/lab/Alert";
import CyberSourceTransactionRequestNSA from "./cyber-source-transaction";
import SkipCashTransactionRequestNSA from "./_skipcash_transaction";
import GooglePayButtonComponent from '../../../common/transaction/google-pay';

const useStyle = makeStyles((theme) => ({
    centerItem: {
        justifyContent: "space-around",
        display: "flex"
    },
    requestHelp : {
        background: grey500,
        textAlign: "center !important",
        color: theme.palette.whiteColor.main+" !important",
        border: "none"
    },
    paddingTopBottom : {
        paddingTop: 10,
        paddingBottom: 10
    },
    buttonsMargin: {
        margin: 5
    },
    bottomPadding: {
        paddingBottom:10
    },
    gpayGrid:{
      textAlignLast: "center"
    }
}))

function Payment(props) {
    const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
    const paymentTypeMigs = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[1]);
    const paymentTypeQPAY = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[0]);
    const paymentTypeCyberSource = useSelector((state) =>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[2]);
    const paymentTypeSkipCash = useSelector((state) =>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[3]);
    const paymentTypeGooglePay = "GooglePay";
    const [amountText, setAmountText] = useState("");
    const [PaymentInstruction, setPaymentInstruction] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [isGooglePay, setIsGooglePay] = useState();
    const GOOGLEPAY_GATEWAY_CODE = useSelector((state)=>state?.parametersWidgets.ParameterLookup.GOOGLEPAY_GATEWAY_CODE);
    let connectionId = localStorage.getItem("connectionId");
    let lead = localStorage.getItem("lead");
    let orderInfo = lead ? lead : connectionId;
    useEffect(()=>{
      if(GOOGLEPAY_GATEWAY_CODE === "CyberSource_Gateway") {
        setIsGooglePay(true); 
      }
    }, [GOOGLEPAY_GATEWAY_CODE])
    useEffect(() => {
        if (props.data) {
          props.data.forEach((item) => {
            if (item.contentCode === "AMOUNT_TEXT") {
                setAmountText(item.answer);
            }
            if (item.contentCode === "PAYMENT_INSTRUCTIONS") {
                setPaymentInstruction(item.answer);
            }
          });
        }
      }, [props.data]);
    let isSkipCashDebitCard = false;
    let isSkipCashCreditCard = false;
    let isCyberSourceGateway = false;
    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
    const currency = useSelector((state)=>state?.parametersWidgets.ParameterLookup.CURRENCY_CODE);
    const classes = useStyle()
    const baseClasses = useStyles()
    const customStyle = customStyles();
    if (
      useSelector((state)=>state?.parametersWidgets.ParameterLookup.CREDIT_CARD_GATEWAY_CODE)
         === "SkipCash_Gateway"
    ) {
      isSkipCashCreditCard = true;
    }
    if( useSelector((state)=>state?.parametersWidgets.ParameterLookup.CREDIT_CARD_GATEWAY_CODE)
      === "CyberSource_Gateway"){
        isCyberSourceGateway=true;
    }
    if (
      useSelector((state)=>state?.parametersWidgets.ParameterLookup.DEBIT_CARD_GATEWAY_CODE)
         === "SkipCash_Gateway"
    ) {
      isSkipCashDebitCard = true;
    }

    const [openLoading, setOpenLoading] = useState(false)
    const handleOpenLoadingClose = () => {
        setOpenLoading(false)
    }
    const log = (logObj) => {
      adminService.log(logObj).then((res) => {
      });
    }
    const [res, setRes] = useState(null);
    const handleDebitCardClick = () => {
      log({ key: "NSA_PAYMENT", message: "Debit Card Clicked", stackOrLog: "STACK" })
        setError(false)
        localStorage.setItem("depositeAmount",(Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2))
        localStorage.setItem("uniqueId",props.formData.uniqueID);
        localStorage.removeItem("ticketno");
        let tempInput = JSON.parse(JSON.stringify(props?.dataObject));
        if(tempInput?.csaAttachments?.length) {
          tempInput.csaAttachments.forEach(item => {
              item.fileContent = "HIDDEN"
          })
        }
        log({ key: "NSA_PAYMENT", message: `Data (${paymentTypeQPAY}) :`, addOnLog: tempInput, stackOrLog: "LOG" })
        localStorage.setItem("paymentProcess", "Y");
        paymentService
        .prePaymentProcessing(
            paymentTypeQPAY,
            props.dataObject
        )
        .then((res) => {
          log({ key: "NSA_PAYMENT", message: "Prepayment API success", stackOrLog: "LOG" })
          localStorage.removeItem("paymentProcess");
          let dataValue = Object.assign({}, props.formData);
          dataValue.jointOwnerDetails.map((item,index)=>{
            item.fileContent="";
          })
          dataValue.csaAttachment.map((item,index)=>{
            item.fileContent="";
          })
          dataValue.FileContent="";
          dataValue.FileContentp="";
          dataValue.FileContentq="";
          dataValue.FileContentAll="";
          const Value = JSON.stringify(dataValue);
          localStorage.setItem("Value",Value);
          setRes(res.data.prePaymentProcessing);
          localStorage.removeItem("orderId");
          localStorage.setItem("orderId", res.data.prePaymentProcessing?.PUN);
          res.data.prePaymentProcessing &&
            document.getElementById("frmTransaction").submit();
        })
        .catch((error) => {
          localStorage.removeItem("paymentProcess");
          log({ key: "NSA_PAYMENT", message: "Prepayment API error", stackOrLog: "STACK" })
          setError(true)
          if(error?.response?.data?.errors[0]?.message){
            log({ key: "NSA_PAYMENT", message: error?.response?.data?.errors[0]?.message, stackOrLog: "STACK" })
            setErrorMessage(error?.response?.data?.errors[0]?.message);
          }
          log({ key: "NSA_PAYMENT", message: "Abort", stackOrLog: "LOG" })
          setOpenLoading(false)
        });
        setOpenLoading(true)
    }

    const appLogo = useSelector((state)=>state?.appLogo?.assetPath);
    const handleCreditCardClick = () => {
      log({ key: "NSA_PAYMENT", message: "Credit Card Clicked", stackOrLog: "STACK" })
        setError(false)
        localStorage.setItem("depositeAmount",(Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2))
        localStorage.setItem("uniqueId",props.formData.uniqueID);
        localStorage.setItem("userName",props.formData.fusername+" "+props.formData.lusername)
        localStorage.setItem("email",props.formData.email);
        localStorage.setItem("mobile",props.formData.mobile);
        localStorage.removeItem("ticketno");
        let tempInput = JSON.parse(JSON.stringify(props?.dataObject))
        if(tempInput?.csaAttachments?.length) {
          tempInput.csaAttachments.forEach(item => {
              item.fileContent = "HIDDEN"
          })
        }
        log({ key: "NSA_PAYMENT", message: `Data (${paymentTypeMigs}) :`, addOnLog: tempInput, stackOrLog: "LOG" })
        localStorage.setItem("paymentProcess", "Y");
        paymentService
        .prePaymentProcessing(
            paymentTypeMigs,
            props.dataObject
            )
        .then((res) => {
          log({ key: "NSA_PAYMENT", message: "Prepayment API success", stackOrLog: "LOG" })
          localStorage.removeItem("paymentProcess");  
            let dataValue = Object.assign({}, props.formData);
            dataValue.jointOwnerDetails.map((item,index)=>{
              item.fileContent="";
            })
            dataValue.csaAttachment.map((item,index)=>{
              item.fileContent="";
            })
            dataValue.FileContent="";
            dataValue.FileContentp="";
            dataValue.FileContentq="";
            dataValue.FileContentAll="";
            const Value = JSON.stringify(dataValue);
            localStorage.setItem("orderId",res.data.prePaymentProcessing.vpc_OrderId);
            localStorage.setItem("Value",Value);
          HostedCheckout(
            res.data.prePaymentProcessing.vpc_OrderId,
            res.data.prePaymentProcessing.vpc_MerchTxnRef,
            res.data.prePaymentProcessing.vpc_Currency,
            res.data.prePaymentProcessing.vpc_Merchant,
            res.data.prePaymentProcessing.vpc_Merchant_Name,
            orderInfo,
            props.formData.depositeAmount,
            props?.dataObject?.mobile,
            props?.dataObject?.email,
            "QAT",
            config.urls.ASSEST_URL_ENDPOINT+"/qc-default-logo.png"
          );
        })
        .catch((error) => {
          localStorage.removeItem("paymentProcess");
          log({ key: "NSA_PAYMENT", message: "Prepayment API error", stackOrLog: "STACK" })
          setError(true)
          if(error?.response?.data?.errors[0]?.message){
            log({ key: "NSA_PAYMENT", message: error?.response?.data?.errors[0]?.message, stackOrLog: "STACK" })
            setErrorMessage(error?.response?.data?.errors[0]?.message);
          }
          log({ key: "NSA_PAYMENT", message: "Abort", stackOrLog: "LOG" })
          setOpenLoading(false)
        });

        setOpenLoading(true)
    }
    const handleGooglPayClick = (orderId) => {
      return new Promise((resolve, reject) => {
        log({ key: "NSA_PAYMENT", message: "Google Pay Clicked", stackOrLog: "STACK" })
        setError(false)
        localStorage.setItem("depositeAmount",(Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2))
        localStorage.setItem("uniqueId",props.formData.uniqueID);
        localStorage.removeItem("ticketno");
        let tempInput = JSON.parse(JSON.stringify(props?.dataObject));
        if(tempInput?.csaAttachments?.length) {
          tempInput.csaAttachments.forEach(item => {
              item.fileContent = "HIDDEN"
          })
        }
        log({ key: "NSA_PAYMENT", message: `Data (${paymentTypeGooglePay}) :`, addOnLog: tempInput, stackOrLog: "LOG" })
        localStorage.setItem("paymentProcess", "Y");
        paymentService
        .prePaymentProcessing(
          paymentTypeGooglePay,
            props.dataObject,
            orderId
        )
        .then((res) => {
          log({ key: "NSA_PAYMENT", message: "Prepayment API success", stackOrLog: "LOG" })
          localStorage.removeItem("paymentProcess");
          let dataValue = Object.assign({}, props.formData);
          dataValue.jointOwnerDetails.map((item,index)=>{
            item.fileContent="";
          })
          dataValue.csaAttachment.map((item,index)=>{
            item.fileContent="";
          })
          dataValue.FileContent="";
          dataValue.FileContentp="";
          dataValue.FileContentq="";
          dataValue.FileContentAll="";
          const Value = JSON.stringify(dataValue);
          localStorage.setItem("Value",Value);
          setRes(res.data.prePaymentProcessing);
          // localStorage.removeItem("orderId");
          // localStorage.setItem("orderId", res.data.prePaymentProcessing?.PUN);
          console.log("successs",res.data.prePaymentProcessing)
          resolve(res.data.prePaymentProcessing); 
        })
        .catch((error) => {
          localStorage.removeItem("paymentProcess");
          log({ key: "NSA_PAYMENT", message: "Prepayment API error", stackOrLog: "STACK" })
          setError(true)
          if(error?.response?.data?.errors[0]?.message){
            log({ key: "NSA_PAYMENT", message: error?.response?.data?.errors[0]?.message, stackOrLog: "STACK" })
            setErrorMessage(error?.response?.data?.errors[0]?.message);
          }
          log({ key: "NSA_PAYMENT", message: "Abort", stackOrLog: "LOG" })
          setOpenLoading(false)
          reject(error);
        });
        setOpenLoading(true)
      })
    }
  return (
    <Dialog onClose={props.closePaySubmit} aria-labelledby="customized-dialog-title" open={props.openPayment} PaperProps={{style:{ borderRadius: 15 }}}>
        <div className={customStyle.lightblueColorBackground}>
        <Typography 
        color="primary"
        variant='body1'>
            <b>{labels_Upfront_Payment?.AMOUNT}<br/></b>
        </Typography>
        <Grid container direction='row' className={classes.paddingTopBottom}>
             <Grid item xs="8">
                    {amountText}     
             </Grid>
             <Grid item xs="4" className={classes.centerItem}>
                <b>{currency}{" "}{Number(parseFloat(props.formData.depositeAmount).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}</b>
             </Grid>
        </Grid>
        <Grid container direction='row' className={[classes.paddingTopBottom,classes.bottomPadding]}>
             <Grid item xs="8">
                <Typography color="primary">
                    <b>{labels_Upfront_Payment?.TOTAL_AMOUNT}</b>
                </Typography>
             </Grid>
             <Grid item xs="4" className={classes.centerItem}>
                <b>{currency}{" "}{Number(parseFloat(props.formData.depositeAmount).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}</b>
             </Grid>
        </Grid>
        <Grid container direction='row' className={classes.paddingTopBottom}>
             <Grid item xs="12" className={classes.centerItem}>
                <Typography color="primary">
                    <b>{labels_Upfront_Payment?.PAY_NOW_WITH}</b>
                </Typography>
             </Grid>
        </Grid>
        <Grid container direction='row' className={classes.bottomPadding}>
             <Grid item xs="12" className={classes.centerItem}>
            <div>
            {res && (
                <form
                method="POST"
                action={res.QPayPaymentRedirectUrl} // redirect url 
                name="frmTransaction"
                id="frmTransaction"
                >
                <input type="hidden" name="Action" value={res.Action} />
                <input
                    type="hidden"
                    name="Amount"
                    value={parseFloat(props.formData.depositeAmount) * 100}
                />
                <input type="hidden" name="BankID" value={res.BankID} />
                <input type="hidden" name="CurrencyCode" value={res.CurrencyCode} />
                <input
                    type="hidden"
                    name="ExtraFields_f14"
                    value={res.ExtraFields_f14}
                />
                <input type="hidden" name="Lang" value={res.Lang} />
                <input type="hidden" name="MerchantID" value={res.MerchantID} />
                <input
                    type="hidden"
                    name="MerchantModuleSessionID"
                    value={res.MerchantModuleSessionID}
                />
                <input type="hidden" name="NationalID" value={res.NationalID} />
                <input type="hidden" name="PUN" value={res.PUN} />
                <input
                    type="hidden"
                    name="PaymentDescription"
                    value={res.PaymentDescription}
                />
                <input type="hidden" name="Quantity" value={res.Quantity} />
                <input
                    type="hidden"
                    name="TransactionRequestDate"
                    value={res.TransactionRequestDate}
                />
                <input type="hidden" name="SecureHash" value={res.SecureHash} />
                </form>
              )}
              <Grid
                container
                direction="row"
                spacing={2}
                align="center"
                justify="center"
              >
                <Grid item>
                {isCyberSourceGateway  ? (
                  <CyberSourceTransactionRequestNSA
                    classes={classes}
                    label={labels}
                    baseClasses={baseClasses}
                    setError={setError}
                    paymentType={paymentTypeCyberSource}
                    log={log}
                    formData={props.formData}
                    dataObject={props.dataObject}
                    error={error}
                    setErrorMessage={setErrorMessage}
                    setOpenLoading={setOpenLoading}
                  />): isSkipCashCreditCard? (<SkipCashTransactionRequestNSA
                    classes={classes}
                    label={labels}
                    labelName={labels.BALANCE_PAGE_CREDIT_CARD}
                    className={[
                      baseClasses.accentButton,
                      classes.buttonsMargin,
                    ]}
                    baseClasses={baseClasses}
                    setError={setError}
                    paymentType={paymentTypeSkipCash}
                    log={log}
                    formData={props.formData}
                    dataObject={props.dataObject}
                    error={error}
                    setErrorMessage={setErrorMessage}
                    setOpenLoading={setOpenLoading}
                  />):<Button
                  variant="contained"
                  size="medium"
                  className={[baseClasses.accentButton,classes.buttonsMargin]}
                  onClick={handleCreditCardClick}
                  >
                      {labels?.BALANCE_PAGE_CREDIT_CARD}
                  </Button>}
                </Grid>
                <Grid item>
                {isSkipCashDebitCard ? (
                  <SkipCashTransactionRequestNSA
                    classes={classes}
                    label={labels}
                    baseClasses={baseClasses}
                    labelName={labels?.BALANCE_PAGE_DEBIT_CARD}
                    className={[
                      baseClasses.accentButtonDebit,
                      classes.buttonsMargin,
                    ]}
                    setError={setError}
                    paymentType={paymentTypeSkipCash}
                    log={log}
                    formData={props.formData}
                    dataObject={props.dataObject}
                    error={error}
                    setErrorMessage={setErrorMessage}
                    setOpenLoading={setOpenLoading}
                  />):( <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    className={[
                      baseClasses.accentButtonDebit,
                      classes.buttonsMargin,
                    ]}
                    onClick={handleDebitCardClick}
                  >
                    {labels?.BALANCE_PAGE_DEBIT_CARD}
                  </Button>)
                }
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    className={[
                      baseClasses.accentButtonDebit,
                      classes.buttonsMargin,
                    ]}
                    onClick={handleDebitCardClick}
                  >
                    {labels?.BALANCE_PAGE_DEBIT_CARD}
                  </Button>
                </Grid> */}
              </Grid>
              {
                isGooglePay &&
                <Grid item className={classes.gpayGrid}>
                  <br />
                  {
                    props?.formData &&
                    <GooglePayButtonComponent
                      state={{
                        amount: (Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2),
                        firstName: props.formData.fusername,
                        lastName: props.formData.lusername,
                        premiseAddress: "DOHA",
                        phoneNum: props.formData.mobile,
                        email: props.formData.email,
                        city: "DOHA",
                        source: "SERVICE_PAY",
                        connectionId: connectionId,
                      }}
                      nsaClick={handleGooglPayClick}
                      disabled={false}
                    />
                  }
                </Grid>
              }
            </div>
          </Grid>
        </Grid>
        {error && 
                <Alert severity="error">{errorMessage == "" ? (labels_Upfront_Payment?.SOMETHING_WRONG) : errorMessage}</Alert>
                }
        <Grid container direction='row' className={classes.paddingTopBottom}>
            <Grid item xs="12">
            <Typography >
                <b>{labels_Upfront_Payment?.IMPORTANT_INSTRUCTIONS}</b>
            </Typography>
            <Typography
            variant='caption' 
            dangerouslySetInnerHTML={{
                __html: PaymentInstruction,
            }}
            >
            </Typography>
            </Grid>
        </Grid>
        <Grid container direction='row' className={classes.paddingTopBottom} style={{paddingBottom:30}}>
             <Grid item xs="12" className={classes.centerItem}>

             </Grid>
        </Grid>
        </div>
        <CircularDialog
        onClose={handleOpenLoadingClose}
        aria-labelledby="simple-dialog-title"
        open={openLoading}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography color="primary" variant="h5">
            <b>{labels.LOADING_DIALOG_TITLE}</b>
          </Typography>
        </DialogTitle>
        <DialogContent align="center">
          <div className="margin-top-20">
            <CircularProgress color="primary" size={60} />
          </div>
        </DialogContent>
        <DialogActions>
          <Typography className="padding-10">
            {labels.LOADING_DIALOG_SUBTITLE}
          </Typography>{" "}
        </DialogActions>
      </CircularDialog>
    </Dialog>
  )
}

export default Payment;